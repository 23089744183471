.App {
  background-color: #282c34;
  color: white;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

.Header-container {
  text-align: center;
  font-size: 2em;
  padding: 5px;
  background-color: #30363e;
}

.Header1 {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.Container {
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;

}

.Number {
  font-size: 8rem;
  font-weight: bold;
}

.Description {
  animation: fadein 2s;
  animation-delay: 0.25s;
  animation-fill-mode: both;
  color: lightgray;
  font-size: 1em;
}

@media all and (min-width: 48em) {
  .Number {
    font-size: 18rem;
  }
  .Description {
    font-size: 1.4em;
  }
}
